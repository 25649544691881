import React from 'react';
import '../Navbar.css';
import {Nav, Navbar} from 'react-bootstrap';
import {AbstractComponent} from './AbstractComponent';

export class NavigationBar extends AbstractComponent {

    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
        };
    }

    requiredAuthority() {
        return null;
    }

    render() {
        return (
            <div className='navbar-wrapper'>
                <Navbar collapseOnSelect expand="md" inverse="true" fluid="true">
                    <Navbar.Brand>
                        <div onClick={this.props.vendorModal.openModal}>
                            <img
                                src="/assets/header_logo.png"
                                height="30px"
                                className="d-inline-block align-top"
                                alt="MyLega logo"
                            />
                        </div>
                    </Navbar.Brand>
                    <Navbar.Text><strong>{super.getDisplayname()}</strong></Navbar.Text>
                    <Navbar.Toggle/>
                    <Navbar.Collapse className="navbar-menu">
                        <Nav>
                            {this.renderNavItem("Lega Check", "/lega-check", 'TRIGGER_LEGA_JOB')}
                            {this.renderNavItem("Lega Benachrichtigungen", "/settings", null)}
                            {this.renderNavItem("Kursverwaltung", "/course-administration", 'EDIT_COURSE_LIST')}
                            {this.renderNavItem(
                                this.hasAuthority('VIEW_USER_LIST') ? "Benutzerverwaltung" : "Mein Profil",
                                "/user-administration",
                                null
                            )}
                            {this.renderNavItem("Logout", "/logout", null)}
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        );
    }

    renderNavItem(name, link, requiredAuthority) {
        if (this.hasAuthority(requiredAuthority) || requiredAuthority === null) {
            return (
                <Nav.Item key={link}>
                    <Nav.Link href={link}>{name}</Nav.Link>
                </Nav.Item>
            );
        } else return null;
    }
}
