import axios from 'axios';
import {AbstractService} from './AbstractService';
import {authenticate, authenticated, checkAppSetup, logout, resetPassword, triggerPasswordReset} from '../model/backend.routes.model';

export class AuthenticationService extends AbstractService {

    static INSTANCE = new AuthenticationService();

    async logout() {
        return await axios.get(this.getUrl(logout));
    }

    async authenticate(login, password) {

        const data = {
            login: login.toLowerCase(),
            password: password
        };

        return await axios.post(this.getUrl(authenticate), new URLSearchParams(data));
    }

    async authenticated() {
        return axios.get(this.getUrl(authenticated));
    }

    async checkAppSetup() {
        return axios.get(this.getUrl(checkAppSetup))
    }

    async triggerPasswordReset(username) {
        return axios.get(this.getUrl(triggerPasswordReset.replaceAll('USERNAME', username)))
    }

    async resetPassword(username, token) {
        return axios.get(this.getUrl(resetPassword.replaceAll('USERNAME', username).replaceAll('TOKEN', token)))
    }
}