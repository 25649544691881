import React from 'react';
import {NavigationBar} from './NavigationBar';
import {AbstractComponent} from './AbstractComponent';

export class NotAuthorized403 extends AbstractComponent {

    requiredAuthority() {
        return undefined;
    }

    render() {
        return (
            <div>
                <NavigationBar vendorModal={this.props.vendorModal}/>
                <h1>403 - Keine Berechtigung</h1>
                <div>
                    Sie haben keine Berechtigung, diese Seite zu nutzen. Bitte wenden Sie sich an die Administration.
                </div>
            </div>
        );
    }

}
