import {AbstractService} from './AbstractService';

export class CountlyService extends AbstractService {

    static INSTANCE = new CountlyService();
    countly = {};

    getAppKey() {
        switch (super.getEnvironmentName()) {
            case 'local':
                return '7072e2dacc0a35812f67a59503f797a41fd7d47b';
            case 'develop':
                return 'e983a7883e567ac122d6eddd875b6c18f53be995';
            case 'mylega': //prod
                return '404df08f9d6c59aa9a873b3ad5f7cf4889269324';
            default:
                return 'e13f46363e049e00688e124e0739c56d69ca9349';
        }
    }

    getPrincipal() {
        if (!sessionStorage.getItem('user')) {
            return null
        }
        return JSON.parse(sessionStorage.getItem('user'));
    }

    init() {
        const Countly = require('countly-sdk-web');
        Countly.app_key = this.getAppKey();
        Countly.url = 'https://countly.it-werklerei.de/';
        Countly.app_version = sessionStorage.getItem('version')

        Countly.q = Countly.q || [];
        Countly.q.push(['track_sessions']);
        Countly.q.push(['track_pageview']);
        Countly.q.push(['track_clicks']);
        Countly.q.push(['track_scrolls']);
        Countly.q.push(['track_errors']);
        Countly.q.push(['track_links']);
        Countly.q.push(['track_forms']);
        Countly.q.push(['collect_from_forms']);
        Countly.init();

        (function () {
            var cly = document.createElement('script');
            cly.type = 'text/javascript';
            cly.async = true;
            cly.src = 'https://countly.it-werklerei.de/sdk/web/countly.min.js';
            cly.onload = function () {
                Countly.init()
            };
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(cly, s);
        })();

        this.countly = Countly;
    }

    loggedIn(username) {
        this.countly.add_event({
            key: 'login',
            segmentation: {
                "client": this.getEnvironmentName(),
                "user": username,
                "appVersion": sessionStorage.getItem('version')
            }
        });
    }

    loggedOut() {
        this.countly.add_event({
            key: 'logout',
            segmentation: {
                "client": this.getEnvironmentName(),
                "user": this.getPrincipal().username,
                "appVersion": sessionStorage.getItem('version')
            }
        });
    }

    userEdited(username) {
        this.countly.add_event({
            key: 'User edited',
            segmentation: {
                "client": this.getEnvironmentName(),
                "user": this.getPrincipal().username,
                "appVersion": sessionStorage.getItem('version'),
                "changedUser": username
            }
        });
    }

    userAdded(username) {
        this.countly.add_event({
            key: 'User added',
            segmentation: {
                "client": this.getEnvironmentName(),
                "user": this.getPrincipal().username,
                "appVersion": sessionStorage.getItem('version'),
                "createdUser": username
            }
        });
    }

    userDeleted(username) {
        this.countly.add_event({
            key: 'User deleted',
            segmentation: {
                "client": this.getEnvironmentName(),
                "user": this.getPrincipal().username,
                "appVersion": sessionStorage.getItem('version'),
                "deletedUser": username
            }
        });
    }

    courseEdited(coursename) {
        this.countly.add_event({
            key: 'Course edited',
            segmentation: {
                "client": this.getEnvironmentName(),
                "user": this.getPrincipal().username,
                "appVersion": sessionStorage.getItem('version'),
                "changedCourse": coursename
            }
        });
    }

    courseAdded(coursename) {
        this.countly.add_event({
            key: 'Course added',
            segmentation: {
                "client": this.getEnvironmentName(),
                "user": this.getPrincipal().username,
                "appVersion": sessionStorage.getItem('version'),
                "createdCourse": coursename
            }
        });
    }

    courseDeleted(coursename) {
        this.countly.add_event({
            key: 'Course deleted',
            segmentation: {
                "client": this.getEnvironmentName(),
                "user": this.getPrincipal().username,
                "appVersion": sessionStorage.getItem('version'),
                "deletedUser": coursename
            }
        });
    }

    error(description) {
        this.countly.add_event({
            key: 'Error appeared',
            segmentation: {
                "client": this.getEnvironmentName(),
                "user": this.getPrincipal().username,
                "appVersion": sessionStorage.getItem('version'),
                "description": description
            }
        });
    }

    codingError(description) {
        this.countly.add_event({
            key: 'Coding error!!!',
            segmentation: {
                "client": this.getEnvironmentName(),
                "user": this.getPrincipal().username,
                "appVersion": sessionStorage.getItem('version'),
                "description": description
            }
        });
    }

    legaSettingsChanged(username) {
        this.countly.add_event({
            key: 'Lega Settings changed',
            segmentation: {
                "client": this.getEnvironmentName(),
                "user": this.getPrincipal().username,
                "appVersion": sessionStorage.getItem('version'),
                "settingsChangedFor": username
            }
        });
    }

    legaSettingsModalOpened(username) {
        this.countly.add_event({
            key: 'Lega Settings opened',
            segmentation: {
                "client": this.getEnvironmentName(),
                "user": this.getPrincipal().username,
                "appVersion": sessionStorage.getItem('version'),
                "settingsOpenedFor": username

            }
        });
    }
    editUserModalOpened(username) {
        this.countly.add_event({
            key: 'Edit User opened',
            segmentation: {
                "client": this.getEnvironmentName(),
                "user": this.getPrincipal().username,
                "appVersion": sessionStorage.getItem('version'),
                "userSettingsOpenedFor": username
            }
        });
    }
    newUserModalOpened() {
        this.countly.add_event({
            key: 'New User opened',
            segmentation: {
                "client": this.getEnvironmentName(),
                "user": this.getPrincipal().username,
                "appVersion": sessionStorage.getItem('version')
            }
        });
    }
    deleteUserModalOpened(username) {
        this.countly.add_event({
            key: 'Delete User opened',
            segmentation: {
                "client": this.getEnvironmentName(),
                "user": this.getPrincipal().username,
                "deleteUserModalOpenedFor": username,
                "appVersion": sessionStorage.getItem('version')
            }
        });
    }

    editCourseModalOpened(coursename) {
        this.countly.add_event({
            key: 'Edit Course opened',
            segmentation: {
                "client": this.getEnvironmentName(),
                "user": this.getPrincipal().username,
                "appVersion": sessionStorage.getItem('version'),
                "courseSettingsOpenedFor": coursename
            }
        });
    }
    newCourseModalOpened() {
        this.countly.add_event({
            key: 'New Course opened',
            segmentation: {
                "client": this.getEnvironmentName(),
                "user": this.getPrincipal().username,
                "appVersion": sessionStorage.getItem('version')
            }
        });
    }
    deleteCourseModalOpened(coursename) {
        this.countly.add_event({
            key: 'Delete Course opened',
            segmentation: {
                "client": this.getEnvironmentName(),
                "user": this.getPrincipal().username,
                "deleteCourseModalOpenedFor": coursename,
                "appVersion": sessionStorage.getItem('version')
            }
        });
    }

   vendorModalOpened() {
       const username = this.getPrincipal() ? this.getPrincipal().username : 'undefined'
        this.countly.add_event({
            key: 'Vendor information opened',
            segmentation: {
                "client": this.getEnvironmentName(),
                "user": username,
                "appVersion": sessionStorage.getItem('version')
            }
        });
    }

    passwordResetTriggerSucess(username) {
        this.countly.add_event({
            key: 'Trigger password reset success',
            segmentation: {
                "client": this.getEnvironmentName(),
                "forUser": username,
                "appVersion": sessionStorage.getItem('version')
            }
        });
    }

    passwordResetTriggerFailed(username) {
        this.countly.add_event({
            key: 'Trigger password reset failed',
            segmentation: {
                "client": this.getEnvironmentName(),
                "withUser": username,
                "appVersion": sessionStorage.getItem('version')
            }
        });
    }

    passwordResetSucess(username) {
        this.countly.add_event({
            key: 'Password reset success',
            segmentation: {
                "client": this.getEnvironmentName(),
                "forUser": username,
                "appVersion": sessionStorage.getItem('version')
            }
        });
    }

    passwordResetFailed(username) {
        this.countly.add_event({
            key: 'Password reset failed',
            segmentation: {
                "client": this.getEnvironmentName(),
                "withUser": username,
                "appVersion": sessionStorage.getItem('version')
            }
        });
    }

    userAdministrationLoaded() {
        this.countly.add_event({
            key: 'User administration loaded',
            segmentation: {
                "client": this.getEnvironmentName(),
                "user": this.getPrincipal().username,
                "appVersion": sessionStorage.getItem('version')
            }
        });
    }

    courseAdministrationLoaded() {
        this.countly.add_event({
            key: 'Course administration loaded',
            segmentation: {
                "client": this.getEnvironmentName(),
                "user": this.getPrincipal().username,
                "appVersion": sessionStorage.getItem('version')
            }
        });
    }

    legaSettingsLoaded() {
        this.countly.add_event({
            key: 'Lega settings loaded',
            segmentation: {
                "client": this.getEnvironmentName(),
                "user": this.getPrincipal().username,
                "appVersion": sessionStorage.getItem('version')
            }
        });
    }

    requestingCourse(courseNumber) {
        this.countly.add_event({
            key: 'Requesting Course',
            segmentation: {
                "client": this.getEnvironmentName(),
                "user": this.getPrincipal().username,
                "courseNumber": courseNumber,
                "appVersion": sessionStorage.getItem('version')
            }
        });
    }

    requestCourseSuccess(courseNumber) {
        this.countly.add_event({
            key: 'Request Course success',
            segmentation: {
                "client": this.getEnvironmentName(),
                "user": this.getPrincipal().username,
                "courseNumber": courseNumber,
                "appVersion": sessionStorage.getItem('version')
            }
        });
    }
    requestCourseFailed(courseNumber) {
        this.countly.add_event({
            key: 'Request Course failed',
            segmentation: {
                "client": this.getEnvironmentName(),
                "user": this.getPrincipal().username,
                "courseNumber": courseNumber,
                "appVersion": sessionStorage.getItem('version')
            }
        });
    }

    getMailToKbiLink(courseNumber) {
        this.countly.add_event({
            key: 'Requesting MailToKbi Link',
            segmentation: {
                "client": this.getEnvironmentName(),
                "user": this.getPrincipal().username,
                "courseNumber": courseNumber,
                "appVersion": sessionStorage.getItem('version')
            }
        });
    }

    createMailToKbi(courseNumber) {
        this.countly.add_event({
            key: 'Create Mail to KBI',
            segmentation: {
                "client": this.getEnvironmentName(),
                "user": this.getPrincipal().username,
                "courseNumber": courseNumber,
                "appVersion": sessionStorage.getItem('version')
            }
        });
    }
}