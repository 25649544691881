import React from 'react';
import '../UserAdministration.css';
import '../App.css';
import {NavigationBar} from './NavigationBar';
import {AbstractComponent} from './AbstractComponent';
import {UserService} from '../services/UserService';
import Modal from 'react-modal';
import {commonError, deleteCourseSuccess, didNotModify, saveCourseSuccess} from '../model/toasts.model';
import {faEdit, faGraduationCap, faPlus, faSave, faTrash, faWindowClose} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FormControl, FormGroup, FormLabel} from 'react-bootstrap';
import {CountlyService} from "../services/CountlyService";
import {CourseService} from "../services/CourseService";

export class CourseAdministration extends AbstractComponent {

    courseService = CourseService.get();
    userService = UserService.get();
    countly = CountlyService.get();

    constructor(props) {
        super(props);

        this.state = {
            courseList: [],
            showEditOptions: false,
            isDeleteModalOpen: false,
            isEditModalOpen: false,
        };

        this.closeDeleteModal = this.closeDeleteModal.bind(this);
        this.openNewCourseModal = this.openNewCourseModal.bind(this);
        this.closeCourseModal = this.closeCourseModal.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();

        this.loadCourseList();

        this.countly.courseAdministrationLoaded()
    }

    loadCourseList() {
        this.courseService.getAllCourses()
            .then(res => {
                this.setState({courseList: res.data})
            }).catch(() => this.checkAuthorization())
    }

    requiredAuthority() {
        return 'EDIT_COURSE_LIST';
    }

    render() {
        return (
            <div>
                <NavigationBar vendorModal={this.props.vendorModal}/>
                <div className='App-content'>
                    {this.renderCourseDeleteModal()}
                    {this.renderCourseEditNewModal()}
                    {this.renderCourseList()}
                </div>
            </div>
        );
    }

    renderCourseList() {
        return (
            <div>
                <div className="row col-12">
                    <div className="col-8 col-lg-9"/>
                    <div className="col-4 col-lg-2">
                        <button
                            className="btn btn-primary create-button"
                            style={{marginBottom: 20}}
                            onClick={this.openNewCourseModal}>
                            <FontAwesomeIcon className="fa-sm" icon={faPlus}/>&nbsp;
                            <FontAwesomeIcon className="fa-sm" icon={faGraduationCap}/>
                        </button>
                    </div>
                    <div className="d-none d-lg-block col-lg-1"/>
                </div>
                <div className="row col-12">
                    <div className="d-none d-lg-block col-lg-1"/>
                    <div className="d-none d-md-block col-md-2"><strong>Kursnummer</strong></div>
                    <div className="col-8 d-md-block col-md-6 col-lg-5"><strong>Kursname</strong></div>
                    <div className="col-4 d-md-block col-lg-2"><strong>Ändern</strong></div>
                    <div className="d-none d-lg-block col-lg-1"/>
                </div>
                {this.state.courseList.map(course => this.renderCourse(course, this))}
            </div>
        )
    }

    renderCourse(course, rootObject) {
        return (
            <div key={course.id} className="row col-12">
                <div className="d-none d-lg-block col-lg-1"/>
                <div className="d-none d-md-block col-md-2">{course.number}</div>
                <div className="col-8 d-md-block col-md-6 col-lg-5">{course.name}</div>
                <div className="col-4 d-md-block col-lg-2">{rootObject.renderCourseEditButtons(course)}</div>
                <div className="d-none d-lg-block col-lg-1"/>
            </div>
        );
    }

    renderCourseEditButtons(course) {
        return (
            <div>
                <button
                    className="btn"
                    onClick={(event) => this.openCourseEditModal(event, course)}
                >
                    <FontAwesomeIcon className="fa-sm" icon={faEdit}/>
                </button>
                <button
                    className="btn"
                    onClick={(event) => this.openCourseDeleteModal(event, course)}
                >
                    <FontAwesomeIcon className="fa-sm" icon={faTrash}/>
                </button>
            </div>
        )
    }

    deleteCourse(course) {
        this.courseService.deleteCourse(course)
            .then(response => {
                this.countly.courseDeleted(course.name);
                this.removeCourse(course.id);
                this.closeDeleteModal();
                this.props.toast.showToast(deleteCourseSuccess);
            })
            .catch(err => this.props.toast.showToast(commonError));
    }

    openCourseEditModal(event, course) {
        const courseCopy = Object.assign({}, course);
        this.setState({
            isEditModalOpen: true,
            courseToEdit: courseCopy
        });
        this.countly.editCourseModalOpened(courseCopy.name)
    }

    openNewCourseModal() {
        const courseCopy = {
            id: null,
            number: '',
            name: ''
        };
        this.setState({
            isEditModalOpen: true,
            courseToEdit: courseCopy
        });
        this.countly.newCourseModalOpened()
    }

    closeCourseModal() {
        this.setState({
            isEditModalOpen: false,
            courseToEdit: undefined
        });
    }

    renderCourseEditNewModal() {
        if (this.state.isEditModalOpen === true && this.state.courseToEdit) {

            const modalStyle = {
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    minWidth: '300px',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)'
                }
            };

            return <Modal
                isOpen={this.state.isEditModalOpen}
                onRequestClose={this.closeCourseModal}
                style={modalStyle}
                appElement={document.getElementById('root')}
                contentLabel="Kurs editieren">
                {this.state.courseToEdit.id === null ?
                        <h2 ref={subtitle => this.subtitle = subtitle}>Kurs anlegen</h2> :
                        <h2 ref={subtitle => this.subtitle = subtitle}>Kurs editieren</h2>
                }
                <div className="useradmin-modal-buttons">
                    <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={() => {
                            this.closeCourseModal()
                        }}>
                        <FontAwesomeIcon className="fa-sm" icon={faWindowClose}/>
                    </button>
                    <button
                        autoFocus
                        disabled={!this.validateCourseEditForm()}
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                            this.saveCourse(this.state.courseToEdit)
                        }}>
                        <FontAwesomeIcon className="fa-sm" icon={faSave}/>
                    </button>
                </div>
                <form>
                    <FormGroup controlId="number">
                        <FormLabel>Kursnummer</FormLabel>
                        <FormControl
                            type="text"
                            value={this.state.courseToEdit.number || ''}
                            onChange={this.handleEditChange}
                        />
                    </FormGroup>
                    <FormGroup controlId="name">
                        <FormLabel>Kursname</FormLabel>
                        <FormControl
                            type="text"
                            value={this.state.courseToEdit.name}
                            onChange={this.handleEditChange}
                        />
                    </FormGroup>
                </form>
            </Modal>;
        } else {
            return <span/>
        }
    }

    validateCourseEditForm() {
        return !(this.state.courseToEdit.name === '' ||
            this.state.courseToEdit.number === '');
    }

    handleEditChange = event => {
        const course = this.state.courseToEdit;
        course[event.target.id] = event.target.value;
        this.setState({
            courseToEdit: course
        });
    };

    saveCourse(course) {
        this.courseService.saveCourse(course)
            .then(res => {
                course.id === null ? this.countly.courseAdded(course.name) : this.countly.courseEdited(course.name);
                this.loadCourseList();
                this.closeCourseModal();
                this.props.toast.showToast(saveCourseSuccess);
            })
            .catch(err => {
                if (err.response.status === 304) {
                    this.loadCourseList();
                    this.closeCourseModal();
                    this.props.toast.showToast(didNotModify);
                } else {
                    this.props.toast.showToast(commonError)
                }
            });
    }

    openCourseDeleteModal(event, course) {
        const courseCopy = Object.assign({}, course);
        this.setState({
            isDeleteModalOpen: true,
            courseToDelete: courseCopy
        });
        this.countly.deleteCourseModalOpened(courseCopy.name)
    }

    closeDeleteModal() {
        this.setState({
            isDeleteModalOpen: false,
            courseToDelete: undefined
        });
    }

    renderCourseDeleteModal() {
        if (this.state.isDeleteModalOpen === true && this.state.courseToDelete) {
            const deleteModalStyle = {
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)'
                }
            };

            return <Modal
                isOpen={this.state.isDeleteModalOpen}
                onRequestClose={this.closeDeleteModal}
                style={deleteModalStyle}
                appElement={document.getElementById('root')}
                contentLabel="Kurs löschen">
                <div>
                    Kurs {this.state.courseToDelete.number} <i>{this.state.courseToDelete.name}</i> löschen?
                    <span>
                <button
                    className="btn"
                    onClick={event => this.deleteCourse(this.state.courseToDelete)}>
                <FontAwesomeIcon className="fa-sm" icon={faTrash}/>
                </button>
                </span>
                </div>
            </Modal>;
        } else {
            return <span/>
        }

    }

    removeCourse(id) {
        const courseList = this.state.courseList;
        const index = courseList.findIndex(r => r.id === id);
        courseList.splice(index, 1);
        this.setState({
            userList: courseList
        });
    }

}
