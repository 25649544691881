import {apiRoute} from '../model/backend.routes.model';

export class AbstractService {

    static INSTANCE = undefined;

    static get() {
        if (this.INSTANCE === undefined) {
            console.log('Error in Service class');
        }
        return this.INSTANCE;
    }

    getUrl(relative) {
        return this.getHost() + apiRoute + relative;
    }

    getHost() {
        return window.location.origin === 'http://local.mylega.de:3000'
            ? 'http://local.mylega.de:8071'
            : window.location.origin;
    }

    getEnvironmentName() {
        return window.location.origin === 'http://local.mylega.de:3000'
            ? 'local'
            : window.location.origin.split('.')[0].replace('https://', '');
    }
}