import React, {Component} from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import './App.css';
import Login from './components/Login';
import Logout from './components/Logout';
import Root from './components/Root';
import {CourseAdministration} from "./components/CourseAdministration";
import {UserAdministration} from './components/UserAdministration';
import {Settings} from './components/Settings';
import {NotFound404} from './components/NotFound404';
import {NotAuthorized403} from './components/NotAuthorized403';
import {CountlyService} from './services/CountlyService'
import {LegaCheck} from "./components/LegaCheck";

export default class App extends Component {

    countlyService = CountlyService.get();

    constructor(props) {
        super(props);
        const version  = process.env.REACT_APP_VERSION;
        console.log("version: " + version);
        sessionStorage.setItem('version', version);
        this.countlyService.init();
    }

    render() {
        return (
            <BrowserRouter>
                <div className="App">
                    <Switch>
                        <Route exact path="/" component={Root}/>
                        <Route path="/login*" render={(props) =>
                            <Login {...props} vendorModal={this.props.vendorModal} toast={this.props.toast}/>}
                        />
                        <Route path="/course-administration" render={(props) =>
                            <CourseAdministration {...props} vendorModal={this.props.vendorModal} toast={this.props.toast}/>}
                        />
                        <Route path="/user-administration" render={(props) =>
                            <UserAdministration {...props} vendorModal={this.props.vendorModal} toast={this.props.toast}/>}
                        />
                        <Route path="/settings" render={(props) =>
                            <Settings {...props} vendorModal={this.props.vendorModal} toast={this.props.toast}/>}
                        />
                        <Route path="/lega-check" render={(props) =>
                            <LegaCheck {...props} vendorModal={this.props.vendorModal} toast={this.props.toast}/>}
                        />
                     ^  <Route path="/403" render={(props) =>
                            <NotAuthorized403 {...props} vendorModal={this.props.vendorModal} toast={this.props.toast}/>}
                        />
                        <Route path="/logout" component={Logout}/>
                        <Route path="*" render={(props) =>
                            <NotFound404 {...props} vendorModal={this.props.vendorModal} toast={this.props.toast}/>}
                        />
                    </Switch>
                </div>
            </BrowserRouter>
        );
    }
}
