import React, {Component} from "react";
import {Button, FormControl, FormGroup, FormLabel} from "react-bootstrap";
import "../Login.css";
import Background from '../images/background.jpg';
import {AuthenticationService} from '../services/AuthenticationService';
import {loginFailed, passwordResetSuccess, appInitiated, passwordResetFailed, triggerPasswordResetSuccess, triggerPasswordResetFailed} from '../model/toasts.model';
import {CountlyService} from "../services/CountlyService";

export default class Login extends Component {

    countly = CountlyService.get();
    authenticationService = AuthenticationService.get();

    constructor(props) {
        super(props);
        sessionStorage.removeItem('user');

        this.state = {
            login: "",
            password: "",
            showPasswordReset: false
        };
    }

    componentDidMount() {
        this.authenticationService.checkAppSetup()
            .then((response) => {
                if (response.data === false) {
                    this.props.toast.showToast(appInitiated)
                }
            });

        if (window.location.pathname !== '/login') {
            let params = window.location.pathname.split('/login')[1];
            let paramsSplit = params.split('/');
            let username = paramsSplit[1];
            if (paramsSplit[2] === 'reset-password') {
                let token = paramsSplit[3];
                this.authenticationService.resetPassword(username, token)
                    .then(() => {
                        this.props.toast.showToast(passwordResetSuccess)
                        this.countly.passwordResetSucess(username)
                    })
                    .catch(() => {
                        this.props.toast.showToast(passwordResetFailed)
                        this.countly.passwordResetFailed(username)
                    })
            }
        }
    }

    validateForm() {
        return this.state.login.length > 0 && this.state.password.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleLogin = async event => {
        event.preventDefault();
        await this.authenticationService.authenticate(this.state.login, this.state.password)
            .then(() => this.handleAuthenticateReturn())
            .catch(() => this.handleAuthenticateReturn());
    };

    handleAuthenticateReturn() {
        this.authenticationService.authenticated()
            .then(() => {
                window.location.pathname = '/';
            })
            .catch(() => this.props.toast.showToast(loginFailed))
    }

    sendPasswordResetTrigger = async event => {
        event.preventDefault();
        await this.authenticationService.triggerPasswordReset(this.state.login)
            .then(() => {
                this.props.toast.showToast(triggerPasswordResetSuccess)
                this.countly.passwordResetTriggerSucess(this.state.login)
            })
            .catch(() => {
                this.props.toast.showToast(triggerPasswordResetFailed)
                this.countly.passwordResetTriggerFailed(this.state.login)
            })
    }

    render() {
        return (
            <div className="bg-image" style={{
                background: `url(${Background})`,
            }}>
                <div className="v-center">
                    <div className="login">
                        <div onClick={this.props.vendorModal.openModal}>
                            <img src="/assets/logo.png" alt="App Logo" className="logo"/>
                        </div>
                        {this.state.showPasswordReset ? this.renderPasswordResetForm() : this.renderLoginForm()}
                        <div>
                            <a onClick={() => this.togglePasswordReset()}>
                                {this.state.showPasswordReset ? "Ich kenne mein Passwort..." : "Passwort vergessen?"}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderLoginForm() {
        return (
            <form onSubmit={this.handleLogin} className="login">
                <FormGroup controlId="login">
                    <FormLabel>Benutzername</FormLabel>
                    <FormControl
                        autoFocus
                        type="text"
                        value={this.state.login}
                        onChange={this.handleChange}
                    />
                </FormGroup>
                <FormGroup controlId="password">
                    <label>Passwort</label>
                    <FormControl
                        value={this.state.password}
                        onChange={this.handleChange}
                        type="password"
                    />
                </FormGroup>
                <Button
                    block
                    style={{marginTop: 2 + 'rem'}}
                    disabled={!this.validateForm()}
                    type="submit"
                >
                    Anmelden
                </Button>
            </form>
        );
    }

    renderPasswordResetForm() {
        return (
            <form onSubmit={this.sendPasswordResetTrigger} className="login">
                <FormGroup controlId="login">
                    <FormLabel>Benutzername</FormLabel>
                    <FormControl
                        autoFocus
                        type="text"
                        value={this.state.login}
                        onChange={this.handleChange}
                    />
                </FormGroup>

                <Button
                    block
                    style={{marginTop: 7.5 + 'em'}}
                    type="submit"
                >
                    Passwort zurücksetzen
                </Button>
            </form>
        );
    }

    togglePasswordReset() {
        this.setState({
            showPasswordReset: !this.state.showPasswordReset
        });
    }
}
