import React from "react";
import "../Login.css";
import {AbstractComponent} from './AbstractComponent';

export default class Root extends AbstractComponent {

    constructor(props) {
        super(props);
        console.log('clearing user');
        sessionStorage.removeItem('user');
    }

    render() {
        return (
            <div className="login">
                <div>
                    <img src="/assets/logo.png" alt="App Logo" className="logo"/>
                </div>
            </div>
        );
    }
}
