import axios from 'axios';
import {AbstractService} from './AbstractService';
import {course} from "../model/backend.routes.model";

export class CourseService extends AbstractService {

    static INSTANCE = new CourseService();

    async getAllCourses() {
        return axios.get(this.getUrl(course));
    }

    async saveCourse(courseToSave) {
        if (courseToSave.id === null) {
            return axios.post(this.getUrl(course), courseToSave);
        } else {
            return axios.put(this.getUrl(course + courseToSave.id), courseToSave);
        }
    }

    async deleteCourse(courseToDelete) {
        return axios.delete(this.getUrl(course + courseToDelete.id), courseToDelete);
    }
}