import axios from 'axios';
import {AbstractService} from './AbstractService';
import {allSettings, settings, triggerLegaChecker} from '../model/backend.routes.model';

export class SettingsService extends AbstractService {

    static INSTANCE = new SettingsService();

    async getMySettings() {
        return axios.get(this.getUrl(settings));
    }

    async saveSettings(settingsToSave) {
        return axios.put(this.getUrl(settings), settingsToSave)
    }

    async getAllSettings() {
        return axios.get(this.getUrl(allSettings));
    }
}