import React from 'react';
import '../Settings.css';
import '../App.css';
import {NavigationBar} from './NavigationBar';
import {AbstractComponent} from './AbstractComponent';
import {legaCheckFailed, legaCheckSuccess, mailToKbiError, notAuthorized, requestCourseFailed, requestCourseSuccess} from '../model/toasts.model';
import {CountlyService} from "../services/CountlyService";
import {LegaCheckService} from "../services/LegaCheckService";

export class LegaCheck extends AbstractComponent {

    legaCheckService = LegaCheckService.get();
    countly = CountlyService.get();

    constructor(props) {
        super(props);

        this.state = {
            mailToKbi: '',
        };
    }

    componentDidMount() {
        super.componentDidMount();

        if (window.location.pathname !== '/lega-check') {
            let params = window.location.pathname.split('/lega-check')[1];
            let paramsSplit = params.split('/');
            if (paramsSplit[1] === 'request-course') {
                let courseNumber = paramsSplit[2];
                this.countly.requestingCourse(courseNumber)
                this.legaCheckService.requestCourse(courseNumber)
                    .then(() => {
                        this.props.toast.showToast(requestCourseSuccess)
                        this.countly.requestCourseSuccess(courseNumber)
                    })
                    .catch(() => {
                        this.props.toast.showToast(requestCourseFailed)
                        this.countly.requestCourseFailed(courseNumber)
                    })
            } else if (paramsSplit[1] === 'mailto-kbi') {
                let courseNumber = paramsSplit[2];
                this.countly.getMailToKbiLink(courseNumber)
                this.legaCheckService.getMailToKbiLink(courseNumber)
                    .then(res => {
                        this.setState({
                            mailToKbi: res.data,
                            mailToKbiCourseNumber: courseNumber
                        })
                    })
                    .catch(() => {
                        this.props.toast.showToast(mailToKbiError)
                    })
            }
        }
    }

    hasTriggerJobAuthority() {
        return super.hasAuthority('TRIGGER_LEGA_JOB')
    }

    hasSendMailToKbiAuthority() {
        return super.hasAuthority('SEND_MAIL_TO_KBI')
    }

    requiredAuthority() {
        return undefined;
    }

    render() {
        return (
            <div>
                <NavigationBar vendorModal={this.props.vendorModal}/>
                <div className='App-content'>
                    {this.renderTriggerJobButton()}
                    {this.renderMailToKbiButton(this)}
                </div>
            </div>
        );
    }

    renderTriggerJobButton() {
        if (this.hasTriggerJobAuthority()) {
            return (
                <div>
                    <button
                        className="btn btn-primary create-button"
                        onClick={() => this.triggerLegaCheck()}>
                        Trigger Lega Checker
                    </button>
                </div>
            )
        } else {
            return <span/>
        }
    }

    renderMailToKbiButton(rootObject) {
        if (this.hasSendMailToKbiAuthority()) {
            return (
                <div>
                    <a className="btn btn-primary create-button" href={ rootObject.state.mailToKbi } onClick={() => rootObject.countly.createMailToKbi(rootObject.state.mailToKbiCourseNumber)}>Erstelle E-Mail an KBM Ausbildung</a>
                </div>
            )
        } else {
            return <span/>
        }
    }

    triggerLegaCheck() {
        if (this.hasTriggerJobAuthority()) {
            this.legaCheckService.triggerLegaCheck()
                .then(res => {
                    this.props.toast.showToast(legaCheckSuccess);
                })
                .catch(err => {
                    this.props.toast.showToast(legaCheckFailed);
                });
        } else {
            this.props.toast.showToast(notAuthorized);
        }
    }
}
