import React from 'react';
import '../App.css';
import {NavigationBar} from './NavigationBar';
import {AbstractComponent} from './AbstractComponent';

export class NotFound404 extends AbstractComponent {

    requiredAuthority() {
        return undefined;
    }

    render() {
        return (
            <div>
                <NavigationBar vendorModal={this.props.vendorModal}/>
                <div className='App-content'>
                    <h1>404 - Seite nicht gefunden</h1>
                    <div>
                        Diese Seite wurden nicht gefunden.
                    </div>
                </div>
            </div>
        );
    }

}
