import {Toast} from 'react-bootstrap';
import '../Toast.css';
import React from 'react';

export class AppToast extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showToast: false,
            content: undefined
        };

        this.showToast = this.showToast.bind(this);
        this.closeToast = this.closeToast.bind(this);
    }

    showToast(content) {
        this.setState({
            showToast: true,
            content: content
        });
    }

    closeToast() {
        this.setState({
            showToast: false
        });
    }

    render() {
        return (
            <Toast className={this.getToastClassName()}
                   onClose={() => this.closeToast()}
                   delay={5000}
                   show={this.state.showToast}
                   autohide>
                <Toast.Body>{this.state.content}</Toast.Body>
            </Toast>
        )
    }

    getToastClassName() {
        if (this.state.showToast) {
            return 'show-toast';
        } else {
            return 'show-toast toast-disabled';
        }
    }


}