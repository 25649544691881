import React from 'react';
import {AuthenticationService} from '../services/AuthenticationService';
import {UserService} from '../services/UserService';
import {CountlyService} from "../services/CountlyService";

export class AbstractComponent extends React.Component {

    authenticationService = AuthenticationService.get();
    userService = UserService.get();
    countly = CountlyService.get();

    componentDidMount() {
        this.checkAuthorization();
    }

    checkAuthorization() {
        this.authenticationService.authenticated()
            .then(() => this.checkAuthorizationCallback())
            .catch(() => window.location.pathname = '/login');
    }

    checkAuthorizationCallback() {
        this.checkRequiredAuthority();
        if (sessionStorage.getItem('user') === null) {
            this.userService.getLoggedInUser()
                .then(userResponse => this.checkUserCallback(userResponse))
                .catch(() => window.location.pathname = '/login');
        }
    }

    checkUserCallback(userResponse) {
        if (userResponse.data) {
            sessionStorage.setItem('user', JSON.stringify(userResponse.data));
            this.forwardToStartpage(userResponse.data);
        } else {
            this.countly.error('userResponse has no data; leading unknown user to /403');
            window.location.pathname = "/403";
        }
    }

    forwardToStartpage(user) {
        let startPage = undefined;
        if (user) {
            startPage = '/settings';
            if (sessionStorage.getItem('deeplink')) {
                startPage = sessionStorage.getItem('deeplink');
                sessionStorage.removeItem('deeplink');
            }
            this.countly.loggedIn(user.username);
            window.location.pathname = startPage;
        }
    }

    checkRequiredAuthority() {
        const principal = JSON.parse(sessionStorage.getItem('user'));
        const requiredRole = this.requiredAuthority();
        if (!requiredRole) {
            return;
        }
        if (principal.authorities.indexOf('SUPER_ADMIN') === -1 && principal.authorities.indexOf(requiredRole) === -1) {
            this.countly.error('required roles not found for user ' + principal.email + '; leading to /403');
            window.location.pathname = "/403";
        }
    }

    requiredAuthority() {
        return undefined;
    }

    getUserId() {
        return AbstractComponent.getPrincipal().id;
    }

    getUsername() {
        return AbstractComponent.getPrincipal().username;
    }

    getDisplayname() {
        return AbstractComponent.getPrincipal().displayname;
    }

    hasAuthority(authority) {
        return AbstractComponent.getPrincipal().authorities.includes(authority);
    }

    static getPrincipal() {
        const principal = JSON.parse(sessionStorage.getItem('user'));
        if (!principal || !principal.authorities) {
            sessionStorage.removeItem('startPage');
            if (!sessionStorage.getItem('deeplink') && window.location.pathname !== '/') {
                sessionStorage.setItem('deeplink', window.location.pathname);
            }
            window.location.pathname = "/";
        }
        return principal;
    }

}
