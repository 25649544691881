import React from 'react';
import '../UserAdministration.css';
import '../App.css';
import {NavigationBar} from './NavigationBar';
import {AbstractComponent} from './AbstractComponent';
import {UserService} from '../services/UserService';
import Modal from 'react-modal';
import {commonError, deleteUserSuccess, didNotModify, saveUserSuccess} from '../model/toasts.model';
import {faEdit, faFireExtinguisher, faPlus, faSave, faUserSlash, faWindowClose} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FormControl, FormGroup, FormLabel} from 'react-bootstrap';
import {CountlyService} from "../services/CountlyService";

export class UserAdministration extends AbstractComponent {

    userService = UserService.get();
    countly = CountlyService.get();

    constructor(props) {
        super(props);

        this.state = {
            userList: [],
            roleList: [],
            showEditOptions: false,
            isDeleteModalOpen: false,
            isEditModalOpen: false,
        };

        this.closeDeleteModal = this.closeDeleteModal.bind(this);
        this.openNewUserModal = this.openNewUserModal.bind(this);
        this.closeEditUserModal = this.closeEditUserModal.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();

        this.loadUsers();
        if (this.hasEnhancedAuthority()) {
            this.loadRoles();
        }

        this.countly.userAdministrationLoaded()
    }

    loadUsers() {
        if (this.hasEnhancedAuthority()) {
            this.userService.getUserList()
                .then(res => {
                    this.setState({userList: res.data})
                }).catch(() => this.checkAuthorization())
        } else {
            this.userService.getLoggedInUser()
                .then(res => {
                    this.setState({userList: [res.data]})
                    this.openUserEditModal(undefined, res.data)
                }).catch(() => this.checkAuthorization())
        }
    }

    loadRoles() {
        this.userService.getRoleList()
            .then(res => {
                this.setState({roleList: res.data})
            });
    }

    hasEnhancedAuthority() {
        return super.hasAuthority('VIEW_USER_LIST')
    }

    hasFullAuthority() {
        return super.hasAuthority('VIEW_USER_LIST') && super.hasAuthority('EDIT_USER_LIST')
    }

    requiredAuthority() {
        return undefined;
    }

    render() {
        return (
            <div>
                <NavigationBar vendorModal={this.props.vendorModal}/>
                <div className='App-content'>
                    {this.hasFullAuthority() ? this.renderUserDeleteModal() : <span/>}
                    {this.renderUserEditNewModal()}
                    {this.hasEnhancedAuthority() ? this.renderUserList() : <span/>}
                </div>
            </div>
        );
    }

    renderUserList() {
        return (
            <div>
                <div className="row col-12">
                    <div className="d-none d-lg-block col-lg-1"/>
                    <div className="col-8 col-md-4 col-lg-3"><strong>Name</strong></div>
                    <div className="d-none d-md-block col-md-4 col-lg-3"><strong>E-Mail</strong></div>
                    <div className="d-none d-lg-block col-lg-2"><strong>Rollen</strong></div>
                    <div className="col-4 col-lg-2"><strong>Ändern</strong></div>
                    <div className="d-none d-lg-block col-lg-1"/>
                </div>
                {this.state.userList.map(user => this.renderUser(user, this))}
                <div className="row col-12">
                    <div className="col-8 col-lg-9"/>
                    <div className="col-4 col-lg-2">
                        <button
                            className="btn btn-primary create-button"
                            onClick={this.openNewUserModal}>
                            <FontAwesomeIcon className="fa-sm" icon={faPlus}/>&nbsp;
                            <FontAwesomeIcon className="fa-sm" icon={faFireExtinguisher}/>
                        </button>
                    </div>
                    <div className="d-none d-lg-block col-lg-1"/>
                </div>
            </div>
        )
    }

    renderUser(user, rootObject) {
        return (
            <div key={user.id} className="row col-12">
                <div className="d-none d-lg-block col-lg-1"/>
                <div className="col-8 col-md-4 col-lg-3">{user.displayname}</div>
                <div className="d-none d-md-block col-md-4 col-lg-3">{user.email}</div>
                {rootObject.hasFullAuthority() ?
                    <div className="d-none d-lg-block col-lg-2">{user.roles.map(role => rootObject.renderRole(role))}</div> :
                    <div className="d-none d-lg-block col-lg-2"/>
                }
                <div className="col-4 col-lg-2">{rootObject.renderUserEditButtons(user)}</div>
                <div className="d-none d-lg-block col-lg-1"/>
            </div>
        );
    }

    renderRole(thisRole) {
        return (
            <div key={thisRole}>{this.state.roleList.filter(role => thisRole === role.name).map(role => role.label)} </div>
        );
    }

    renderUserEditButtons(user) {
        return (
            <div>
                <button
                    className="btn"
                    disabled={this.hasEnhancedAuthority()
                        && user.roles.indexOf('SUPER_ADMIN') > -1
                        && !this.hasAuthority('SUPER_EDIT_USER_LIST')}
                    onClick={(event) => this.openUserEditModal(event, user)}>
                    <FontAwesomeIcon className="fa-sm" icon={faEdit}/>
                </button>
                {
                    this.hasAuthority('EDIT_USER_LIST') ?
                        <button
                            disabled={user.id === this.getUserId() ||
                                (user.roles.indexOf('SUPER_ADMIN') > -1 && !this.hasAuthority('SUPER_EDIT_USER_LIST'))}
                            className="btn"
                            onClick={(event) => this.openUserDeleteModal(event, user)}>
                            <FontAwesomeIcon className="fa-sm" icon={faUserSlash}/>
                        </button> :
                        <span/>
                }
            </div>
        )
    }


    deleteUser(user) {
        this.userService.deleteUser(user)
            .then(respone => {
                this.countly.userDeleted(user.username);
                this.removeUser(user.id);
                this.closeDeleteModal();
                this.props.toast.showToast(deleteUserSuccess);
            })
            .catch(err => this.props.toast.showToast(commonError));
    }

    openUserEditModal(event, user) {
        const userCopy = Object.assign({}, user);
        userCopy.passwordToSet = '';
        this.setState({
            isEditModalOpen: true,
            userToEdit: userCopy
        });
        this.countly.editUserModalOpened(userCopy.username)
    }

    openNewUserModal() {
        const userCopy = {
            id: null,
            displayname: '',
            username: '',
            email: '',
            prename: '',
            lastname: '',
            passwordToSet: '',
            roles: []
        };
        this.setState({
            isEditModalOpen: true,
            userToEdit: userCopy
        });
        this.countly.newUserModalOpened()
    }

    closeEditUserModal() {
        this.setState({
            isEditModalOpen: false,
            userToEdit: undefined
        });
    }

    renderUserEditNewModal() {
        if (this.state.isEditModalOpen === true && this.state.userToEdit) {

            function renderRoleCheckboxes(rootObject) {

                function handleCheckbox(event, roleName) {
                    const checkedBoxes = [...rootObject.state.userToEdit.roles];
                    if (event.target.checked) {
                        checkedBoxes.push(roleName)
                    } else {
                        const index = checkedBoxes.findIndex((_role) => _role === roleName);
                        checkedBoxes.splice(index, 1);
                    }
                    const userToEdit = rootObject.state.userToEdit;
                    userToEdit.roles = checkedBoxes;
                    rootObject.setState({userToEdit: userToEdit});
                }
                if (rootObject.hasEnhancedAuthority()) {
                    return (
                        <div>
                            {rootObject.state.roleList.map(role => {
                                return (
                                    <div key={role.name}>
                                        <label htmlFor={role.label}>
                                            <input
                                                type="checkbox"
                                                id={role.label}
                                                name="role"
                                                disabled={
                                                    rootObject.state.userToEdit.id === rootObject.getUserId() ||
                                                    (role.name === 'SUPER_ADMIN' && !rootObject.hasAuthority('SUPER_EDIT_USER_LIST'))
                                                }
                                                checked={rootObject.state.userToEdit.roles.find((_role) => _role === role.name)}
                                                onChange={(event) => handleCheckbox(event, role.name)}
                                            />
                                            <span className="role-edit-item">{role.label}</span>
                                        </label>
                                    </div>
                                );
                            })}
                        </div>
                    )
                } else {
                    return ( <span/> )
                }
            }

            const modalStyle = {
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    minWidth: '300px',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)'
                }
            };

            return <Modal
                isOpen={this.state.isEditModalOpen}
                onRequestClose={this.closeEditUserModal}
                style={modalStyle}
                appElement={document.getElementById('root')}
                contentLabel="User editieren">
                {this.hasEnhancedAuthority() ? this.state.userToEdit.id === null ?
                        <h2 ref={subtitle => this.subtitle = subtitle}>Benutzer anlegen</h2> :
                        <h2 ref={subtitle => this.subtitle = subtitle}>Benutzer editieren</h2> :
                    <h2 ref={subtitle => this.subtitle = subtitle}>Mein Profil</h2>
                }
                <div className="useradmin-modal-buttons">
                    <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={() => {
                            this.closeEditUserModal()
                        }}>
                        <FontAwesomeIcon className="fa-sm" icon={faWindowClose}/>
                    </button>
                    <button
                        autoFocus
                        disabled={!this.validateUserEditForm()}
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                            this.saveUser(this.state.userToEdit)
                        }}>
                        <FontAwesomeIcon className="fa-sm" icon={faSave}/>
                    </button>
                </div>
                <form>
                    <FormGroup controlId="username">
                        <FormLabel>Benutzername</FormLabel>
                        <FormControl
                            type="text"
                            readOnly={this.state.userToEdit.id !== null && !super.hasAuthority('SUPER_EDIT_USER_LIST')}
                            value={this.state.userToEdit.username}
                            onChange={this.handleEditChange}
                        />
                    </FormGroup>
                    <FormGroup controlId="prename">
                        <FormLabel>Vorname</FormLabel>
                        <FormControl
                            type="text"
                            readOnly={!this.hasEnhancedAuthority()}
                            value={this.state.userToEdit.prename}
                            onChange={this.handleEditChange}
                        />
                    </FormGroup>
                    <FormGroup controlId="lastname">
                        <FormLabel>Nachname</FormLabel>
                        <FormControl
                            type="text"
                            readOnly={!this.hasEnhancedAuthority()}
                            value={this.state.userToEdit.lastname}
                            onChange={this.handleEditChange}
                        />
                    </FormGroup>
                    <FormGroup controlId="email">
                        <FormLabel>E-Mail</FormLabel>
                        <FormControl
                            type="email"
                            value={this.state.userToEdit.email}
                            onChange={this.handleEditChange}
                        />
                    </FormGroup>
                    <FormGroup controlId="passwordToSet">
                        <label>Passwort ändern</label>
                        <FormControl
                            type="password"
                            value={this.state.userToEdit.passwordToSet}
                            onChange={this.handleEditChange}
                        />
                    </FormGroup>
                    {this.hasEnhancedAuthority() ? <FormGroup controlId="roles">
                        <label>Rollen</label>
                        {renderRoleCheckboxes(this)}
                    </FormGroup> : <span/>}
                </form>
            </Modal>;
        } else {
            return <span/>
        }

    }

    validateUserEditForm() {
        if (this.hasEnhancedAuthority()) {
            if (this.state.userToEdit.username === '' ||
                this.state.userToEdit.lastname === '') {
                return false
            }

            if (this.state.userToEdit.roles === null || this.state.userToEdit.roles.length === 0) {
                return false
            }
            return true
        }
        return this.state.userToEdit.passwordToSet !== ''
    }

    handleEditChange = event => {
        const user = this.state.userToEdit;
        user[event.target.id] = event.target.value;
        this.setState({
            userToEdit: user
        });
    };

    saveUser(user) {
        this.userService.saveUser(user)
            .then(res => {
                user.id === null ? this.countly.userAdded(user.username) : this.countly.userEdited(user.username);
                this.loadUsers();
                this.closeEditUserModal();
                this.props.toast.showToast(saveUserSuccess);
            })
            .catch(err => {
                if (err.response.status === 304) {
                    this.loadUsers();
                    this.closeEditUserModal();
                    this.props.toast.showToast(didNotModify);
                } else {
                    this.props.toast.showToast(commonError)
                }
            });
    }

    openUserDeleteModal(event, user) {
        const userCopy = Object.assign({}, user);
        this.setState({
            isDeleteModalOpen: true,
            userToDelete: userCopy
        });
        this.countly.deleteUserModalOpened(userCopy.username)
    }

    closeDeleteModal() {
        this.setState({
            isDeleteModalOpen: false,
            userToDelete: undefined
        });
    }

    renderUserDeleteModal() {
        if (this.state.isDeleteModalOpen === true && this.state.userToDelete) {
            const deleteModalStyle = {
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)'
                }
            };

            return <Modal
                isOpen={this.state.isDeleteModalOpen}
                onRequestClose={this.closeDeleteModal}
                style={deleteModalStyle}
                appElement={document.getElementById('root')}
                contentLabel="User löschen">
                <div>
                    Benutzer {this.state.userToDelete.displayname} löschen?
                    <span>
                <button
                    className="btn"
                    onClick={event => this.deleteUser(this.state.userToDelete)}>
                <FontAwesomeIcon className="fa-sm" icon={faUserSlash}/>
                </button>
                </span>
                </div>
            </Modal>;
        } else {
            return <span/>
        }

    }

    removeUser(id) {
        let userList = this.state.userList;
        const index = userList.findIndex(r => r.id === id);
        userList.splice(index, 1);
        this.setState({
            userList: userList
        });
    }

}
