import axios from 'axios';
import {AbstractService} from './AbstractService';
import {currentUser, role, user} from '../model/backend.routes.model';

export class UserService extends AbstractService {

    static INSTANCE = new UserService();

    async getLoggedInUser() {
        return axios.get(this.getUrl(currentUser));
    }

    async getUserList() {
        return axios.get(this.getUrl(user));
    }

    async getRoleList() {
        return axios.get(this.getUrl(role));
    }

    async deleteUser(userToDelete) {
        return axios.delete(this.getUrl(user + userToDelete.id));
    }

    async saveUser(userToSave) {
        if (userToSave.passwordToSet !== null) {
            userToSave.passwordToSet = userToSave.passwordToSet.trim().length === 0 ? null : userToSave.passwordToSet;
        }
        if (userToSave.id === null) {
            return axios.post(this.getUrl(user), userToSave);
        } else {
            return axios.put(this.getUrl(user + userToSave.id), userToSave);
        }
    }

}