import React, {Component} from "react";
import "../Login.css";
import {AuthenticationService} from '../services/AuthenticationService';
import {CountlyService} from "../services/CountlyService";

export default class Logout extends Component {

    authenticationService = AuthenticationService.get();
    countly = CountlyService.get();

    componentDidMount() {
        this.authenticationService.logout()
            .then(() => this.performUiLogout())
            .catch(() => this.performUiLogout());
    }

    performUiLogout() {
        this.countly.loggedOut();
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('deeplink');
        window.location.pathname = '/';
    }

    render() {
        return (
            <div className="login">
                <div>
                    <img src="/assets/logo.png" alt="App Logo" className="logo"/>
                </div>
            </div>
        );
    }
}