import React from 'react';
import '../Settings.css';
import '../App.css';
import {NavigationBar} from './NavigationBar';
import {AbstractComponent} from './AbstractComponent';
import Modal from 'react-modal';
import {commonError, didNotModify, saveSettingsSuccess} from '../model/toasts.model';
import {faEdit, faInbox, faSave, faStickyNote, faWindowClose} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FormControl, FormGroup, FormLabel} from 'react-bootstrap';
import {CountlyService} from "../services/CountlyService";
import {SettingsService} from "../services/SettingsService";
import {CourseService} from "../services/CourseService";

export class Settings extends AbstractComponent {

    settingsService = SettingsService.get();
    courseService = CourseService.get();
    countly = CountlyService.get();

    constructor(props) {
        super(props);

        let settings = {
            id: null,
            username: '',
            userDisplayname: '',
            email: '',
            telegramUserId: '',
            sendMailNotification: '',
            sendTelegramNotification: '',
            oneDayCourses: '',
            courses: []
        };
        this.state = {
            settingsList: [ settings ],
            courseList: [],
            isEditSettingsModalOpen: false,
        };

        this.closeEditSettingsModal = this.closeEditSettingsModal.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();

        this.loadCourseList()
        this.loadSettings()

        this.countly.legaSettingsLoaded()
    }

    loadCourseList() {
        this.courseService.getAllCourses()
            .then(res => {
                this.setState({courseList: res.data})
            }).catch(() => this.checkAuthorization())
    }

    loadSettings() {
        if (this.hasEnhancedAuthority()) {
            this.settingsService.getAllSettings()
                .then(res => {
                    this.setState({settingsList: res.data})
                }).catch(() => this.checkAuthorization())
        } else {
            this.settingsService.getMySettings()
                .then(res => {
                    this.setState({settingsList: [res.data]})
                    this.openSettingsEditModal(undefined, res.data)
                }).catch(() => this.checkAuthorization())
        }
    }


    hasEnhancedAuthority() {
        return super.hasAuthority('VIEW_ALL_LEGA_SETTINGS')
    }

    hasFullAuthority() {
        return super.hasAuthority('VIEW_ALL_LEGA_SETTINGS') && super.hasAuthority('EDIT_ALL_LEGA_SETTINGS')
    }

    requiredAuthority() {
        return undefined;
    }

    render() {
        return (
            <div>
                <NavigationBar vendorModal={this.props.vendorModal}/>
                <div className='App-content'>
                    {this.renderEditModal()}
                    {this.renderSettingsList()}
                </div>
            </div>
        );
    }

    renderSettingsList() {
        if (this.hasEnhancedAuthority()) {
            return (
                <div>
                    <div className="row col-12">
                        <div className="col-6 col-md-4 col-lg-3"><strong>Benutzer</strong></div>
                        <div className="d-none d-md-block col-md-4"><strong>E-Mail</strong></div>
                        <div className="d-none d-lg-block col-lg-2"><strong>Telegram-Id</strong></div>
                        <div className="col-3 col-md-2 col-lg-1"><strong>aktiv</strong></div>
                        <div className="col-3 col-md-2"><strong>&Auml;ndern</strong></div>
                    </div>
                    {this.state.settingsList.map(settings => this.renderSettings(settings, this))}
                </div>
            )
        } else {
            return <span/>
        }
    }

    renderSettings(settings, rootObject) {
        return (
            <div key={settings.id} className="row col-12">
            <div className="col-6 col-md-4 col-lg-3">{settings.userDisplayname}</div>
                <div className="d-none d-md-block col-md-4">{settings.email}</div>
                <div className="d-none d-lg-block col-lg-2">{settings.telegramUserId}</div>
                <div className="col-3 col-md-2 col-lg-1">{settings.sendMailNotification ?
                    <FontAwesomeIcon className="fa-thin overview-notification" icon={faInbox} title="Mail" alt="Mail"/> :
                    <span/>}
                    {settings.sendTelegramNotification ?
                        <FontAwesomeIcon className="fa-thin" icon={faStickyNote} title="Telegram" alt="Telegram"/> :
                        <span/>}
                </div>
                <div className="col-3 col-md-2">{rootObject.renderSettingsEditButtons(settings)}</div>
            </div>
        );
    }

    renderSettingsEditButtons(settings) {
        return (
            <div>
                <button
                    className="btn"
                    disabled={this.hasEnhancedAuthority()
                        && ( settings.username === "admin" || settings.userDisplayname.indexOf("Plucik") > -1 )
                        && !this.hasAuthority('SUPER_EDIT_USER_LIST')}
                    onClick={(event) => this.openSettingsEditModal(event, settings)}>
                    <FontAwesomeIcon className="fa-sm" icon={faEdit}/>
                </button>
            </div>
        )
    }


    openSettingsEditModal(event, settings) {
        const settingsCopy = Object.assign({}, settings);
        this.setState({
            isEditSettingsModalOpen: true,
            settingsToEdit: settingsCopy
        });
        this.countly.legaSettingsModalOpened(settingsCopy.username)
    }


    closeEditSettingsModal() {
        this.setState({
            isEditSettingsModalOpen: false,
            settingsToEdit: undefined
        });
    }

    renderEditModal() {
        if (this.state.isEditSettingsModalOpen === true && this.state.settingsToEdit) {

            function renderCourseCheckboxes(rootObject) {

                function handleCheckbox(event, course) {
                    const checkedBoxes = [...rootObject.state.settingsToEdit.courses];
                    if (event.target.checked) {
                        checkedBoxes.push(course)
                    } else {
                        const index = checkedBoxes.findIndex((_course) => _course.number === course.number);
                        checkedBoxes.splice(index, 1);
                    }
                    const settingsToEdit = rootObject.state.settingsToEdit;
                    settingsToEdit.courses = checkedBoxes;
                    rootObject.setState({settingsToEdit: settingsToEdit});
                }

                return (
                    <div>
                        {rootObject.state.courseList.map(course => {
                            return (
                                <div key={course.number}>
                                    <label htmlFor={course.name}>
                                        <input
                                            type="checkbox"
                                            id={course.name}
                                            name="course"
                                            checked={rootObject.state.settingsToEdit.courses.find((_course) => _course.number === course.number)}
                                            onChange={(event) => handleCheckbox(event, course)}
                                        />
                                        <span className="course-edit-item">{course.name}</span>
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                )
            }

            const modalStyle = {
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    minWidth: '25em',
                    overflow: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)'
                }
            };

            return <Modal
                isOpen={this.state.isEditSettingsModalOpen}
                onRequestClose={this.closeEditSettingsModal}
                style={modalStyle}
                appElement={document.getElementById('root')}
                contentLabel="Einstellungen ändern">
                {this.hasEnhancedAuthority() && super.getUsername() !== this.state.settingsToEdit.username ?
                    <h3 ref={subtitle => this.subtitle = subtitle}>{this.state.settingsToEdit.userDisplayname}</h3> :
                    <h3 ref={subtitle => this.subtitle = subtitle}>Meine Einstellungen</h3>
                }
                <div className="settings-modal-buttons">
                    <button
                        autoFocus
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                            this.saveSettings(this.state.settingsToEdit)
                        }}>
                        <FontAwesomeIcon className="fa-sm" icon={faSave}/>
                    </button>
                    <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={() => {
                            this.closeEditSettingsModal()
                        }}>
                        <FontAwesomeIcon className="fa-sm" icon={faWindowClose}/>
                    </button>
                </div>
                <form>
                    <FormGroup controlId="email">
                        <FormLabel>E-Mail</FormLabel>
                        <FormControl
                            type="email"
                            value={this.state.settingsToEdit.email}
                            onChange={this.handleEditChange}
                        />
                    </FormGroup>
                    <FormGroup controlId="telegramUserId">
                        <FormLabel>Telegram User Id</FormLabel>
                        <FormControl
                            type="text"
                            value={this.state.settingsToEdit.telegramUserId}
                            onChange={this.handleEditChange}
                        />
                    </FormGroup>
                    <div key="sendMailNotification">
                        <label htmlFor="sendMailNotification">
                            <input
                                type="checkbox"
                                id="sendMailNotification"
                                checked={this.state.settingsToEdit.sendMailNotification}
                                onChange={this.handleEditChange}
                            />
                            <span className="course-edit-item">per Mail benachrichtigen</span>
                        </label>
                    </div>
                    <div key="sendTelegramNotification">
                        <label htmlFor="sendTelegramNotification">
                            <input
                                type="checkbox"
                                id="sendTelegramNotification"
                                checked={this.state.settingsToEdit.sendTelegramNotification}
                                onChange={this.handleEditChange}
                            />
                            <span className="course-edit-item">per Telegram benachrichtigen</span>
                        </label>
                    </div>
                    <div key="oneDayCourses">
                        <label htmlFor="oneDayCourses">
                            <input
                                type="checkbox"
                                id="oneDayCourses"
                                checked={this.state.settingsToEdit.oneDayCourses}
                                onChange={this.handleEditChange}
                            />
                            <span className="course-edit-item">Tageslehrgänge</span>
                        </label>
                    </div>
                    <FormGroup controlId="courses">
                        <label><strong>Kurse</strong></label>
                        <div className="course-list-wrapper">
                            {renderCourseCheckboxes(this)}
                        </div>
                    </FormGroup>
                </form>
            </Modal>
        } else {
            return <span/>
        }

    }

    handleEditChange = event => {
        const settings = this.state.settingsToEdit;
        if (event.target.id.indexOf('Notification') > -1 ||
            event.target.id.indexOf('oneDay') > -1) {
            settings[event.target.id] = event.target.checked === true
        } else {
            settings[event.target.id] = event.target.value
        }
        this.setState({
            settingsToEdit: settings
        });
    };

    saveSettings(settings) {
        this.settingsService.saveSettings(settings)
            .then(res => {
                this.countly.legaSettingsChanged(settings.username);
                this.loadSettings();
                this.closeEditSettingsModal();
                this.props.toast.showToast(saveSettingsSuccess);
            })
            .catch(err => {
                if (err.response.status === 304) {
                    this.loadSettings();
                    this.closeEditSettingsModal();
                    this.props.toast.showToast(didNotModify);
                } else {
                    this.props.toast.showToast(commonError);
                }
            });
    }
}
