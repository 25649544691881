import axios from 'axios';
import {AbstractService} from './AbstractService';
import {getMailToKbiLink, requestCourse, triggerLegaChecker} from '../model/backend.routes.model';

export class LegaCheckService extends AbstractService {

    static INSTANCE = new LegaCheckService();

    async triggerLegaCheck() {
        return axios.get(this.getUrl(triggerLegaChecker))
    }

    async requestCourse(courseNumber) {
        return axios.post(this.getUrl(requestCourse.replaceAll('COURSE_NUMBER', courseNumber)))
    }

    async getMailToKbiLink(courseNumber) {
        return axios.get(this.getUrl(getMailToKbiLink.replaceAll('COURSE_NUMBER', courseNumber)))
    }
}
