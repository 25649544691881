import React, {Component} from "react";
import "../VendorModal.css";
import Modal from 'react-modal';
import {CountlyService} from "../services/CountlyService";

const modalStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

export default class VendorModal extends Component {

    countly = CountlyService.get();
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    closeModal() {
        this.setState({
            isModalOpen: false,
        });
    }

    openModal() {
        this.setState({
            isModalOpen: true,
        });

        this.countly.vendorModalOpened()
    }

    render() {
        return (
            <div>
                <Modal
                    isOpen={this.state.isModalOpen}
                    onRequestClose={this.closeModal}
                    style={modalStyle}
                    appElement={document.getElementById('root')}
                    contentLabel="it werklerei Modal">
                    <div className="vendor-modal">
                        <h1>MyLega</h1>
                        <div className="version">
                            Version: {sessionStorage.getItem('version')}
                        </div>
                        <hr/>
                        <div>
                            <strong>made with &hearts; by</strong><br/><br/>
                        </div>
                        <div>
                            <a href="https://it-werklerei.de" target="_blank" rel="noopener noreferrer">
                                <img src="/assets/it-werklerei.png" alt="it werklerei Logo" className="vendor-modal-logo"/>
                            </a>
                            <br/>
                            <br/>
                        </div>
                        <div>
                            mail <a href="mailto:support@it-werklerei.de">support@it-werklerei.de</a><br/>
                            web <a href="https://it-werklerei.de" target="_blank" rel="noopener noreferrer">it-werklerei.de</a>
                        </div>
                    </div>
                </Modal>
            </div>);
    }

}